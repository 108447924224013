<template>
  <nav class="AccountLayoutNavigation">
    <router-link class="link"
                 :to="{ name: 'account.profile' }">Profile</router-link>
    <router-link class="link" v-if="[role.Admin].includes(user.role)"
                 :to="{ name: 'account.subscription' }">Subscription</router-link>
    <router-link class="link" v-if="[role.Admin].includes(user.role) && user.subscription_plan.name !== 'Free'"
                 :to="{ name: 'account.addons' }">Addons</router-link>
    <router-link class="link" v-if="[role.Admin, role.Accountant].includes(user.role)"
                 :to="{ name: 'account.receipts' }">Receipts</router-link>
    <router-link class="link" v-if="[role.Admin].includes(user.role)"
                 :to="{ name: 'account.usage' }">Usage</router-link>
    <router-link class="link" v-if="[role.Admin].includes(user.role)"
                 :to="{ name: 'account.teammates' }">Teammates</router-link>
    <router-link class="link" v-if="[role.Admin, role.Editor].includes(user.role)"
                 :to="{ name: 'account.api' }">API</router-link>
    <router-link v-if="[role.Admin, role.Editor].includes(user.role)" class="link"
                 :to=" {name: 'account.general-notifications'}">General Alerts</router-link>
    <router-link v-if="[role.Admin].includes(user.role)" class="link"
               :to=" {name: 'account.monitor.settings'}">Monitor Settings</router-link>
    <router-link class="link" v-if="[role.Admin].includes(user.role)"
                 :to="{ name: 'account.tfa' }">2FA</router-link>
    <router-link class="link"
                 :to="{ name: 'account.delete' }">Delete account</router-link>
  </nav>
</template>

<script>
import { Role } from '@/_helpers/role.js'
export default {
  data () {
    return {
      role: Role
    }
  },

  computed: {
    ...mapState('authentication', [
      'user'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .AccountLayoutNavigation {
    display: flex;
    flex-wrap: wrap;
    padding-top: 40px;

    .link {
      display: block;
      padding: 0 12px 12px 12px;
      font-size: 15px;
      transition: color 0.2s;
      font-weight: 500;
      margin-bottom: -1px;

      &:not(.router-link-active) {
        color: lighten(map-get($colors, gray-1), 20%);

        &:hover {
          color: map-get($colors, gray-1);
          border-bottom: 1px solid lighten(map-get($colors, gray-1), 20%);
        }
      }

      &.router-link-active {
        border-bottom: 2px solid map-get($colors, purple-1);
      }
    }
  }
</style>
